<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ softwareId ? 'Update': 'Add' }} exam software
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Exam software
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="software.title"
                    :error="$v.software.title.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Title <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.software.title.$error">Title is required</span>
                <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="software.version_number"
                    :error="$v.software.version_number.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Version Number <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.software.version_number.$error">Version Number is required</span>
                <span class="text-danger" v-if="errors.version_number" >* {{ errors.version_number[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="software.version_date"
                        :error="$v.software.version_date.$error"
                        chips
                        small-chips
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template v-slot:label>
                        Version Date <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="software.version_date"
                      outlined
                      dense
                      no-title
                      @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.software.version_date.$error">Version Date is required</span>
                <span class="text-danger" v-if="errors.version_date" >* {{ errors.version_date[0] }}</span>
              </v-col>


              <v-col cols="12" md="6">
                <v-file-input
                    label="Mac File"
                    v-model="software.uploadMacFile"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    clearable
                    outlined
                    dense
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6">
                <v-file-input
                    label="Windows File"
                    v-model="software.uploadWinFile"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    clearable
                    outlined
                    dense
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6">
                <v-file-input
                    label="MSI File"
                    v-model="software.uploadMSIFile"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    clearable
                    outlined
                    dense
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Notes"
                    v-model="software.notes"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Announcement Title"
                    v-model="software.announcement_title"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                Announcement Description
                <ckeditor
                    :config="editorConfig"
                    v-model="software.announcement_description"
                >
                </ckeditor>
              </v-col>

              <v-col cols="12" md="6">
                Requirement Mac
                <ckeditor
                    :config="editorConfig"
                    v-model="software.requirement_mac"
                >
                </ckeditor>
              </v-col>

              <v-col cols="12" md="6">
                Requirement Windows
                <ckeditor
                    :config="editorConfig"
                    v-model="software.requirement_windows"
                >
                </ckeditor>
              </v-col>
              <v-col cols="12" md="6">
                Status
                <v-switch
                    v-model="software.is_active"
                    :label="software.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12 text-right">
                <v-btn
                    :loading="loading"
                    @click="createOrUpdate"
                    class="btn btn-primary text-white"
                >Save</v-btn>
                <v-btn
                    @click="close"
                    class="ml-4 btn btn-standard text-white"
                >Cancel</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {required } from "vuelidate/lib/validators";
import ExamSoftwareService from "@/services/cms/exam-software/ExamSoftwareService";


const examSoftware = new ExamSoftwareService();

export default {
  validations: {
    software: {
      title: { required },
      version_number: { required },
      version_date: { required },
    }
  },
  data() {
    return{
      loading: false,
      menu: false,
      errors: [],
      softwareId: '',
      software: {
        title: '',
        version_number: '',
        version_date: '',
        uploadMacFile: null,
        uploadWinFile: null,
        uploadMSIFile: null,
        requirement_mac: '',
        requirement_windows: '',
        notes: '',
        announcement_title: '',
        announcement_description: '',
        is_active: true
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  mounted() {
    this.softwareId = this.$route.params.softwareId;
    this.softwareDetail();
  },
  methods:{
    close() {
      this.$tabs.close();
      this.errors = [];
      // this.resetForm();
    },
    softwareDetail() {
      if (this.softwareId != null || this.softwareId != undefined) {
        examSoftware
            .show(this.softwareId)
            .then(response => {
              this.software = response.data.examSoftware;
            }).catch( err => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.software) {
        if (key === "uploadMacFile" && this.software[key] != null && this.software[key] != undefined) {
          formData.append('uploadMacFile', this.software[key]);
        } else if (key === "uploadWinFile" && this.software[key] != null && this.software[key] != undefined) {
          formData.append('uploadWinFile', this.software[key]);
        } else if (key === "uploadMSIFile" && this.software[key] != null && this.software[key] != undefined) {
          formData.append('uploadMSIFile', this.software[key]);
        } else {
          if(this.software[key]){
            formData.append(key,this.software[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (this.softwareId == null || this.softwareId == undefined) this._create();
        else this.update();
      }
    },
    _create: function () {
      let form = this.convertToFormData();
      examSoftware
          .create(form)
          .then(response => {
            this.$snotify.success("Exam software added");
            this.resetForm();
            this.$tabs.close();
            this.loading = false;

          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });

    },
    update: function () {
      let form = this.convertToFormData();
      examSoftware
          .update(this.software.id, form)
          .then((response) => {
            this.$snotify.success("Exam software updated");
            this.resetForm();
            this.$tabs.close();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.software = {
        title: '',
        version_number: '',
        version_date: '',
        uploadMacFile: null,
        uploadWinFile: null,
        uploadMSIFile: null,
        requirement_mac: '',
        requirement_windows: '',
        notes: '',
        announcement_title: '',
        announcement_description: '',
        is_active: true
      };
    },
  }
}
</script>
